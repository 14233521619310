import React from 'react'
import logo from './logoImg.png'
import './index.module.css'

const Logo = () => {
  return (
    <img src={logo}/>
  )
}

export default Logo